/* Provide sufficient contrast against white background */
a {
  color: #0366d6;
}

code {
  color: #e01a76;
}

.btn-primary {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac;
}

.jobno-color-part1 span {
  color: #000000;
}

.btn-Cyl-kensahyo-ss {
  display: flex;
  align-items: center;
  justify-content: center;
}

.disabled-row {
  background-color: #CCCCCC;
  color: #666;
  pointer-events: none;
}

.disabled-row svg {
  opacity: 0.5;
}
